import React from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import apiUrl from './base_url';

import Footer from './footer';
import Header from './header';

class PaymentC extends React.Component {
  render() {
    return (
      <div id="aboutus">
       <Header/>
        <Navbar  expand="lg">
          <Container>
            <Row>
              <Col lg={12}>
              <h1>Payment Terms & Condition / Refund & Cancellation Policy </h1>
<hr />
<h2>Payment Related Terms & Condition</h2>
Validity of Online Mock Test Series: The validity of the online mock test series offered on 
this website will be one year from the date of enrollment. After one year, access to the mock 
tests will be terminated, and no refund or extension will be provided. Validity of Payment: The validity of 
payment made for the online mock test series will also be one year from the date of enrollment. After one year, 
the payment will be considered expired, and no refund or extension will be provided. Test Series Content: 
The online mock test series content and materials, including but not limited to the questions, answers, explanations, 
and solutions, are the property of ErwaToday.in and are protected by copyright, trademark, and other intellectual property laws. You may not copy, reproduce, distribute, modify, or create derivative works from any of the test series content or materials without our prior written consent. Test Series Conduct: The online mock test series is conducted for educational and testing purposes only. Any attempt to cheat or engage in any fraudulent activity will result in immediate termination of access to the test series and may result in legal action. Technical Issues: We strive to provide a smooth and uninterrupted online test experience, but we cannot guarantee the absence of technical issues, including but not limited to server downtime, internet connectivity issues, or software errors. We will not be held liable for any loss or damage caused by such technical issues. Refund Policy: 
We do not offer refunds for the online mock test series, except in cases of technical issues that prevent access to the tests. In such cases, we will provide a full refund upon verification of the issue. Modifications to Test Series: We reserve the right to modify the online mock test series content, format, and duration at any time without prior notice. Your continued use of the test series after any such modifications constitutes your acceptance of the changes. Termination of Access: We reserve the right to terminate your access to the online mock test series at any time, without prior notice or refund, 
if you breach these terms and conditions or engage in any fraudulent activity.
 By accessing and using the online mock test series on this website, 
 you accept and agree to be bound by these additional terms and conditions.
 <h2>Refund Policy:/Cancellation Policy:</h2>

 Refund Policy/Cancellation Policy:

<h4>Refund Policy:</h4>
In the case of an unsuccessful transaction or a transaction that was not authorized by the user, 
the payment gateway must initiate a refund within a reasonable time frame.
 The refund amount will be credited back to the user's account through the same payment mode used for the transaction. 
 The payment gateway should also provide a receipt or confirmation of the refund to the user. 
 The refund process typically takes around 5 to 7 days to complete.

<h4>Cancellation Policy:</h4> 
In case the user wishes to cancel a transaction, 
they must be allowed to do so within a specific time frame. 
The payment gateway must clearly state the cancellation policy and any associated charges. 
The user must be informed of any cancellation charges or deductions that may apply. 
Example: Suppose a user wants to purchase an online mock test series from a website that uses a payment gateway. After making the payment, the user realizes that they have selected the wrong test series or want to cancel the transaction for some reason. In such a scenario, the payment gateway must allow the user to cancel the transaction within a specified time frame, say 24 hours. The payment gateway should clearly state the cancellation policy and any charges that may apply. If the user cancels within the specified time frame, the payment gateway must initiate a refund of the full transaction amount through the same payment mode used for the transaction. The payment gateway should also provide a receipt or confirmation of the cancellation and refund to the user. If there are any deductions or charges, the payment gateway should clearly state the same and refund the remaining amount to the user. In conclusion, payment gateways must have clear and transparent refund and cancellation policies in place to protect consumers and build trust in the digital payment ecosystem. These policies must be in accordance with the guidelines set forth by the RBI.

              </Col>
            </Row>
          </Container>
        </Navbar>
        <Footer></Footer>
      </div>
    );
  }
}

export default PaymentC;
