import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import Footer from './footer';
import Header from './header';
import apiUrl from './base_url';
import axios from 'axios';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../components/cssfile/question_paper.css';

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      addcategory: [],
      message: [],
    };
  }

  componentDidMount() {
    axios.get(apiUrl + 'serverside/message.php')
      .then(response => {
        this.setState({ message: response.data });
      })
      .catch(error => {
        console.error(error);
      });

    axios.get(apiUrl + 'serverside/parentcategory.php')
      .then(response => {
        this.setState({ addcategory: response.data });
      })
      .catch(error => {
        console.error(error);
      });
  }

  addSeries = (event, category) => {
    event.preventDefault();
    const regid = localStorage.getItem('regid');

    if (!regid) {
      window.location.href = '/login';
      return;
    }

    this.setState({ category });
    axios.post(apiUrl + 'serverside/addseries.php', { category, regid })
      .then(() => {
        window.location.href = '/dashboard';
      })
      .catch(error => {
        console.error(error);
        alert('An error occurred. Please try again.');
      });
  };

  // Function to handle "View More" toggle
  toggleDescription = (index) => {
    const updatedCategories = [...this.state.addcategory];
    updatedCategories[index].isExpanded = !updatedCategories[index].isExpanded;
    this.setState({ addcategory: updatedCategories });
  }

  render() {
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div id="home" style={{ backgroundColor: '#f9f9f9' }}>
        <Header />

        <div className='container mt-4'>
          {/* Slider Section */}
          <Slider {...sliderSettings}>
            {this.state.message.map((item, index) => (
              <div key={index} className="slider-item">
                <div className="card shadow-sm" style={{ width: '99%', height: '200px', borderRadius: '10px', background: '#fff' }}>
                  <div className="card-body">
                    <div className="text-muted text-center">
                      {ReactHtmlParser(item.des)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>

          {/* Main Content Section */}
          <h3 className="text-center mt-5">Online Mock Test Series</h3>

          <p className="text-center text-muted">
            Online mock test series have become an essential tool for candidates preparing for various government exams.
            These mock tests provide candidates with an opportunity to practice and assess their preparation for the actual
            exam. With advancements in technology, online mock test series have become more accessible and convenient for
            candidates to take from the comfort of their homes.
          </p>

          {/* Categories Section */}
          <div className="row mt-5">
            {this.state.addcategory
              .filter((item) => item.status == 1) // Include only active test series
              .map((item, index) => (
                <div key={index} className="col-lg-3 col-md-4 col-sm-6 mb-4">
                  <div className="card shadow-sm" style={{ borderRadius: '10px' }}>
                    {/* Image Wrapper for Centering */}
                    <div className="d-flex justify-content-center align-items-center mt-3">
                      <img
                        src={item.image}
                        className="card-img-top"
                        alt={item.topic_name}
                        style={{
                          height: '150px',
                          width: '150px',
                          borderRadius: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title text-center">{item.topic_name}</h5>

                      {/* Display truncated or full description based on state */}
                      <div className="topic-description" style={{ marginBottom: "20px", color: "#666", lineHeight: "1.6" }}>
                        {ReactHtmlParser(item.isExpanded ? item.total_set : item.total_set.slice(0, 300) + '')}
                      </div>

                      {/* Conditionally render the "View More" button */}
                      {item.total_set.length > 300 && (
                        <button
                          className="btn btn-link p-0 text-primary"
                          onClick={() => this.toggleDescription(index)}>
                          {item.isExpanded ? 'Show Less' : 'View More'}
                        </button>
                      )}

                      <button
                        className="btn btn-outline-primary w-100 mt-3 rounded-pill"
                        onClick={(e) => this.addSeries(e, item.category)}
                      >
                        Get Started Now
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>

        </div>

        <Footer />
      </div>
    );
  }
}

export default Home;
