import React from 'react';
import axios from 'axios';
import HOC from './HOC';
import { Link } from 'react-router-dom';
import { FaAngular, FaSignOutAlt } from "react-icons/fa";
import { Navbar, Nav, Card, Title, Text } from 'react-bootstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import apiUrl from './base_url';
import logo from './img/erwatodaylogo.png';

class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            discount: 0,
            isDiscounted: false,
            
            payment: [],
            email: "",
            regid: '',
            phone: "",
            key:"rzp_live_AQkJ7i0IS8F2nj",
          //  key: "rzp_test_OltsY1ZXXmB8WZ",
            checked: false, // New state variable
        };

    }


    componentDidMount() {


        axios.get(apiUrl+`serverside/payment.php`)
            .then(response => {
                console.log(response.data);
                this.setState({ payment: response.data });
            })
            .catch(error => {
                console.log(error)
            })


        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        script.onload = () => {
            console.log('Razorpay library loaded');
        };
        document.body.appendChild(script);
    }


    handleCheckboxToggle = () => {
        this.setState({ checked: !this.state.checked });
    };


    handlePayment = () => {
        const { payment, key, checked, discount, isDiscounted } = this.state;
        const email = localStorage.getItem('email');
        const regid = localStorage.getItem('regid');
    
        if (!checked) {
            alert("Please check the Payment Privacy and Policy checkbox.");
            return;
        }
    
        // Calculate total payment amount
        const amount = payment.reduce((acc, item) => acc + item.amount, 0);
    
        const options = {
            key: key,
            amount: isDiscounted ? Math.round((amount - (amount * discount) / 100) * 100) : Math.round(amount * 100),
            email: email,
            regid: regid,
            currency: "INR",
            name: "ErwaToday",
            image: logo,
            description: "Erwatoday",
            handler: function (response) {
                const data = {
                    payment_id: response.razorpay_payment_id,
                    amount: amount,
                    email: email,
                    regid: regid,
                };
                axios.post(apiUrl+'serverside/user_payment.php', data)
                    .then((response) => {
                        console.log(response.data);
                        alert(response.data);
                        this.props.history.push('/dashboard');
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }.bind(this),
            prefill: {
                email: email,
                regid: regid,
            },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };
    



    render() {
        const email = localStorage.getItem('email');
        const password = localStorage.getItem('password');
        const regid = localStorage.getItem('regid');
        // alert(regid);
        if (!email || !regid) {
            this.props.history.push('/login'); // Redirect to login page
            return null; // Render nothing
        }
        return (
            <div className='container' style={{ marginTop: '40px' ,backgroundColor:'white',textAlign:'center'}} id="panel">
                <div id="form">
                    <div style={{ overflowY: 'scroll', height: '230px' }}>
                        <h6 id="h3"> Payment Privacy and Policy</h6>

                        {this.state.payment.map((item) => (
                            <div>
                                <div>{ReactHtmlParser(item.des)}</div>
                            </div>
                        ))}

                    </div>
                    <br></br>
                    <div >
                        {this.state.payment.map((item) => (
                            <div>
                                <div>
                                    <h5 id="h3">
                                        Total Paid Amount :{" "}
                                        {ReactHtmlParser(
                                            this.state.isDiscounted
                                                ? `₹${item.amount - (item.amount * this.state.discount) / 100}`
                                                : `₹${item.amount}`
                                        )}
                                    </h5>

                                    <p >Validity : {ReactHtmlParser(item.validity)} from date of payment !</p>
                                    <label style={{ color: 'red' }} >Discount : {ReactHtmlParser(item.discount)}%  </label>{'  '}
                                    <button className='btn btn-primary' onClick={() => this.setState({ discount: item.discount, isDiscounted: true })} >
                                        Apply Discount
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <label style={{ color: 'green' }} id="panel">
                        <input type="checkbox" checked={this.state.checked} onChange={this.handleCheckboxToggle} />
                        &nbsp;I have read and agree to the Payment Privacy and Policy.
                    </label>
                    <p>Please read the Payment Privacy and Policy carefully before proceeding with the payment.</p>
                    <button className='btn btn-primary' onClick={this.handlePayment}>Pay Now</button>
                </div>
            </div>
        );
    }

}
export default HOC(Payment);