import React from 'react';
import axios from 'axios';
import HOC from './HOC';
import { Link } from 'react-router-dom';
import { FaAngular, FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import { Navbar, Nav, Card, Title, Text,Well } from 'react-bootstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import apiUrl from './base_url';

import '../components/cssfile/header.css';
import '../components/cssfile/home.css';
import '../components/cssfile/instruction.css';
import '../components/cssfile/footer.css';
import '../components/cssfile/admin.css';
import '../components/cssfile/card.css';
import '../components/cssfile/ca.css';
import '../components/cssfile/sidebar.css';
import '../components/cssfile/dashboard.css';

class Instruction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            instruction: [],
            language: '', // new state variable for selected language
        }
        this.handleLanguageChange = this.handleLanguageChange.bind(this); // bind the handler function to this component
        this.handleNext = this.handleNext.bind(this); // bind the handler function to this component
    }

    handleLanguageChange(event) { // handler function to update selected language
        this.setState({ language: event.target.value });
    }

    handleNext() { // handler function for "Next" button
        // add your code here to handle navigation to the next page
    }

    componentDidMount() {
        const test_id = localStorage.getItem('test_id');
        const param4 = test_id;
        axios.get(apiUrl+`serverside/instruction.php?test_id=${param4}`)
            .then(response => {
                console.log(response.data);
                this.setState({ instruction: response.data, isLoading: false });
            })
            .catch(error => {
                console.log(error)
            })
    }


    render() {

        const email = localStorage.getItem('email');
        const password = localStorage.getItem('password');
        const regid = localStorage.getItem('regid');
        const test_id = localStorage.getItem('test_id');

        const exam_id1 = this.props.location.state.id;
        const exam_id = localStorage.setItem('exam_id', exam_id1);
        // alert(regid);
        if (!email || !regid) {
            this.props.history.push('/login'); // Redirect to login page
            return null; // Render nothing
        }

        return (
            <div id="instruction">
            <div className='well'  style={{ boxShadow: "1px 0px 4px #9E9E9E", color: "black",height:'45px' }}>
                <h3 style={{paddingLeft:'20px'}}>ErwaToday</h3>
            </div>
            <div className='container-fluid' >
            
                <div className='row' style={{ overflowY: 'scroll', height: '580px', userSelect: 'none', overflow: 'hidden' }}>
                <div 
  className="col-sm-9 content-container" 
  style={{ overflowY: "scroll", height: "635px", userSelect: "none", padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px" }}
>
  {this.state.instruction.map((item) => (
    <div key={item.id} className="instruction-item" style={{ marginBottom: "30px" }}>
      {/* Language Selector */}
      <div 
        className="language-selector" 
        style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "15px" }}
      >
        <label style={{ marginRight: "10px", fontWeight: "bold", color: "#555" }}>Default Language:</label>
        <select 
          value={this.state.language} 
          onChange={this.handleLanguageChange} 
          style={{ padding: "5px", border: "1px solid #ddd", borderRadius: "4px" }}
        >
          <option value="">...select...</option>
          <option value="hi">Hindi</option>
          <option value="en">English</option>
        </select>
      </div>

      {/* Topic Title */}
      <h5 style={{ marginBottom: "15px", color: "#333", fontWeight: "600" }}>
        {this.props.location.state.topic_name} | {item.topic_name}
      </h5>

      {/* Topic Description */}
      <div className="topic-description" style={{ marginBottom: "20px", color: "#666", lineHeight: "1.6" }}>
        {ReactHtmlParser(item.des)}
      </div>

      {/* Language Warning */}
      {!this.state.language && (
        <div 
          className="language-warning" 
          style={{ position: "fixed", bottom: "1px", left: "50%", transform: "translateX(-50%)", width: "100%", backgroundColor: "#ffe0e0", borderRadius: "8px", padding: "10px 20px", textAlign: "center", color: "#a00", fontWeight: "bold" }}
        >
          <h4>Please select a language before starting the test!</h4>
        </div>
      )}

      {/* Start Test Button */}
      {this.state.language && (
        <div 
          className="start-test-container" 
          style={{ position: "fixed", bottom: "1px", left: "50%", transform: "translateX(-50%)", width: "100%", textAlign: "center" }}
        >
          <Link 
            to={{ 
              pathname: "/edashboard", 
              state: { 
                exam_id: exam_id1, 
                test_id: test_id, 
                regid: regid, 
                email: email, 
                topic_name: item.topic_name 
              } 
            }}
          >
            <button 
              className="btn btn-primary" 
              style={{ bottom:"1px",width: "100%", padding: "10px 20px", fontSize: "16px", fontWeight: "bold", borderRadius: "8px" }}
            >
              Start Test Now
            </button>
          </Link>
        </div>
      )}
    </div>
  ))}
</div>



                    <div 
  className="col-sm-2 user-profile" 
  style={{ marginLeft: "20px", height: "300px", display: "flex", alignItems: "center", justifyContent: "center" }}
>
  <div className="profile-card" style={{ textAlign: "center", padding: "20px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "10px", backgroundColor: "#ffffff" }}>
    <FaUserCircle style={{ fontSize: "80px", color: "#6c757d" }} />
    <h6 style={{ marginTop: "15px", fontSize: "16px", fontWeight: "bold", color: "#343a40" }}>{email}</h6>
    <p style={{ fontSize: "14px", color: "#6c757d", margin: "5px 0" }}>ID: {regid}</p>
  </div>
</div>


                </div>
            </div>
            </div>

        );
    }
}

export default Instruction;