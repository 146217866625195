import React from 'react';
import axios from 'axios';
import HOC from './HOC';
import { Link } from 'react-router-dom';
import { Card, Button, Container, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { FaSignOutAlt } from "react-icons/fa";
import apiUrl from './base_url';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            series: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        const email = localStorage.getItem('email');
        const password = localStorage.getItem('password');

        if (!email || !password) {
            this.props.history.push('/login');
            return;
        }

        axios
            .get(apiUrl + `serverside/userdata.php?email=${email}&password=${password}`)
            .then(response => {
                const regid = response.data[0]?.regid || '';
                this.setState({ user: response.data });
                localStorage.setItem('regid', regid);
                this.getSeriesData(regid);
            })
            .catch(error => console.error(error));
    }

    getSeriesData(regid) {
        axios
            .get(apiUrl + `serverside/seriesdata.php?regid=${regid}`)
            .then(response => {
                this.setState({ series: response.data, isLoading: false });
                localStorage.setItem('series', JSON.stringify(response.data));
            })
            .catch(error => console.error(error));
    }

    handleLogout = () => {
        localStorage.clear();
        this.props.history.push('/login');
    };

    render() {
        const { series, isLoading } = this.state;

        return (
            <Container fluid className="py-4">
                <header className="d-flex justify-content-between align-items-center mb-4">
                    <h2>Dashboard</h2>
                    
                </header>

                {isLoading ? (
                    <div className="text-center my-5">
                        <Spinner animation="border" />
                        <p>Loading data...</p>
                    </div>
                ) : series.length > 0 ? (
                    <Row>
                        {series.map((item, index) => (
                            <Col sm={6} md={4} lg={3} key={index} className="mb-4">
                                <Card className="h-100 shadow-sm">
                                    <Card.Img
                                        variant="top"
                                        src={item.image}
                                        alt={item.topic_name}
                                        style={{ height: '150px', objectFit: 'cover' }}
                                    />
                                    <Card.Body>
                                        <Card.Title style={{fontSize:'15px'}}>{item.topic_name}</Card.Title>
                                        
                                        <Button 
                                          variant="info"
                                            as={Link}
                                            to={{
                                                pathname: `${item.url}`,
                                                state: { a_id: item.a_id, topic_name: item.topic_name },
                                            }}
                                        >
                                            Get Started Now
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <Alert variant="info" className="text-center">
                        <h4>No Series Available!</h4>
                        <p>Please add a series to continue.</p>
                        <Button as={Link} to="/home" variant="primary">
                            Add Series
                        </Button>
                    </Alert>
                )}
            </Container>
        );
    }
}

export default HOC(Dashboard);
