import React from 'react';
import axios from 'axios';
import Footer from './footer';
import Header from './header';
import apiUrl from './base_url';
import '../components/cssfile/question_paper.css';
import { Modal, Button, Form } from 'react-bootstrap';

class QuestionPaperDownload extends React.Component {
  constructor() {
    super();
    this.state = {
      question_papers: [],
      showModal: false,
      mobileNumber: '',
      selectedFile: null,
      selectedQuestionPaperId: null,
      downloadAllowed: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    axios
      .get(apiUrl + 'serverside/question_paper.php')
      .then((response) => {
        this.setState({ question_papers: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleModalOpen = (file, questionPaperId) => {
    this.setState({
      showModal: true,
      selectedFile: file,
      selectedQuestionPaperId: questionPaperId,
      downloadAllowed: false,
      errorMessage: '',
    });
  };

  handleModalClose = () => {
    this.setState({
      showModal: false,
      mobileNumber: '',
      selectedFile: null,
      selectedQuestionPaperId: null,
      errorMessage: '',
    });
  };

  handleMobileInputChange = (e) => {
    this.setState({ mobileNumber: e.target.value });
  };

  verifyMobileNumberAndId = () => {
    const { mobileNumber, selectedQuestionPaperId } = this.state;

    if (!mobileNumber || mobileNumber.length !== 10) {
      this.setState({ errorMessage: 'Please enter a valid 10-digit mobile number.' });
      return;
    }

    axios
      .post(apiUrl + 'serverside/question_paper_pay_pro.php', {
        mobile: mobileNumber,
        pay_id: selectedQuestionPaperId,
      })
      .then((response) => {
        if (response.data.success) {
          this.setState({ downloadAllowed: true, errorMessage: '' });
        } else {
          this.setState({ errorMessage: response.data.message || 'Access denied.' });
        }
      })
      .catch((error) => {
        console.error('Verification Error:', error);
        this.setState({ errorMessage: 'An error occurred. Please try again.' });
      });
  };

  render() {
    const {
      question_papers,
      showModal,
      selectedFile,
      downloadAllowed,
      errorMessage,
    } = this.state;

    return (
      <div className="bg-light">
        <Header />
        <div className="container mt-5">
          <h3 className="text-center mb-4">Download Question Papers</h3>
          <div className="row">
            {question_papers.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-sm-6 mb-4">
                <div className="card shadow-lg border-0 rounded-4">
                  <div className="card-body text-center">
                    <h5 className="card-title">{item.topic_name}</h5>
                    <button
                      className="btn btn-primary w-100 mt-3"
                      onClick={() => this.handleModalOpen(item.file, item.id)}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Modal show={showModal} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Verify Mobile Number</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Enter your 10-digit mobile number:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="10"
                  value={this.state.mobileNumber}
                  onChange={this.handleMobileInputChange}
                  placeholder="Enter mobile number"
                />
              </Form.Group>
              {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}
              {downloadAllowed && (
                <a href={selectedFile} download className="btn btn-success w-100 mt-3">
                  Download Now
                </a>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {!downloadAllowed && (
              <Button variant="primary" onClick={this.verifyMobileNumberAndId}>
                Verify
              </Button>
            )}
            <Button variant="secondary" onClick={this.handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Footer />
      </div>
    );
  }
}

export default QuestionPaperDownload;
