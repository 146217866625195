import React from 'react';
import axios from 'axios';
import Footer from './footer';
import Header from './header';
import apiUrl from './base_url';
import '../components/cssfile/question_paper.css';
import logo from './img/erwatodaylogo.png';
import ReactHtmlParser from 'react-html-parser';
import { Modal, Button } from 'react-bootstrap';

class Question_paper extends React.Component {
  constructor() {
    super();
    this.state = {
      question_paper: [],
      successfulPayments: {},
      showModal: false,
      selectedItem: null,
    };
  }

  componentDidMount() {
    axios
      .get(apiUrl + 'serverside/question_paper.php')
      .then((response) => {
        this.setState({ question_paper: response.data });
      })
      .catch((error) => {
        console.error('Error fetching question papers:', error);
      });
  }

  toggleDescription = (index) => {
    const updatedPapers = [...this.state.question_paper];
    updatedPapers[index].isExpanded = !updatedPapers[index].isExpanded;
    this.setState({ question_paper: updatedPapers });
  };

  savePaymentToDatabase = (phone, paymentId, amount, questionPaperId) => {
    axios
      .post(apiUrl + 'serverside/question_paper_pay.php', {
        phone,
        payment_id: paymentId,
        amount,
        question_paper_id: questionPaperId,
      })
      .then((response) => {
        console.log('Payment saved to database:', response.data);
      })
      .catch((error) => {
        console.error('Error saving payment to database:', error);
      });
  };

  handlePaymentSuccess = (response) => {
    const { selectedItem } = this.state;
    const phone = "9608372362";
    const paymentId = response.razorpay_payment_id;
    const amount = selectedItem.amount;
    const questionPaperId = selectedItem.id;

    alert(`Payment successful. Transaction ID: ${paymentId}`);

    this.savePaymentToDatabase(phone, paymentId, amount, questionPaperId);

    this.setState((prevState) => ({
      successfulPayments: {
        ...prevState.successfulPayments,
        [questionPaperId]: true,
      },
      showModal: false,
    }));
  };

  handlePayment = (item) => {
    this.setState({ showModal: true, selectedItem: item });
  };

  handlePaymentProcess = () => {
    const { selectedItem } = this.state;

    if (selectedItem) {
      const options = {
        key: "rzp_live_AQkJ7i0IS8F2nj",
        amount: selectedItem.amount * 100,
        currency: "INR",
        name: "ErwaToday",
        description: `Purchase for ${selectedItem.topic_name}`,
        image: logo,
        handler: this.handlePaymentSuccess,
        prefill: {
          name: "User Name",
          email: "user@example.com",
          contact: "9608372362",
        },
        theme: { color: "#0d6efd" },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    }
  };

  render() {
    return (
      <div className="bg-light">
        <Header />
        <div className="container mt-5">
          <div className="row">
            {this.state.question_paper.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-sm-10 mb-4">
                <div className="card shadow-lg border-0 rounded-4 h-100 hover-card">
                  <div className="d-flex justify-content-center align-items-center mt-2">
                    <img
                      src={item.image}
                      className="card-img-top rounded-circle shadow-sm"
                      alt={item.topic_name}
                      style={{ height: '150px', width: '150px', objectFit: 'cover' }}
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title text-center mb-3">{item.topic_name}</h5>
                    <div
                      className="topic-description"
                      style={{ marginBottom: '20px', color: '#666', lineHeight: '1.6', fontSize: '14px' }}
                    >
                      {ReactHtmlParser(item.isExpanded
                        ? item.des
                        : item.des.slice(0, 100) + '...')}
                    </div>
                    {item.des.length > 100 && (
                      <button
                        className="btn btn-link p-0 text-primary"
                        onClick={() => this.toggleDescription(index)}
                      >
                        {item.isExpanded ? 'Show Less' : 'View More'}
                      </button>
                    )}
                    {item.status === 'prime' ? (
                      <div>
                        <p className="text-center text-success fw-bold">
                          Amount: ₹{item.amount}
                        </p>
                        {this.state.successfulPayments[item.id] ? (
                          <a
                            href={item.file}
                            download
                            className="btn btn-success w-100 mt-3 rounded-pill"
                          >
                            Download Now
                          </a>
                        ) : (
                          <button
                            className="btn btn-primary w-100 mt-3 rounded-pill"
                            onClick={() => this.handlePayment(item)}
                          >
                            Buy Now
                          </button>
                        )}
                      </div>
                    ) : item.status === 'nonprime' ? (
                      <a
                        href={item.file}
                        download
                        className="btn btn-outline-success w-100 mt-3 rounded-pill"
                      >
                        Download Now
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {this.state.showModal && this.state.selectedItem && (
          <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
            <Modal.Header closeButton>
              <Modal.Title>Complete Your Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <img src={logo} alt="ErwaToday" className="img-fluid mb-4" style={{ maxWidth: '100px' }} />
                <h5>{this.state.selectedItem.topic_name}</h5>
                <p>Amount: ₹{this.state.selectedItem.amount}</p>
                <p>Proceed with the payment to complete your purchase.</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={this.handlePaymentProcess}
              >
                Proceed to Pay
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <Footer />
      </div>
    );
  }
}

export default Question_paper;
