import React from 'react';
import axios from 'axios';
import Footer from './footer';
import Header from './header';
import apiUrl from './base_url';
import ReactHtmlParser from 'react-html-parser';
import { FaFacebook, FaTwitter, FaLinkedin, FaWhatsapp, FaThumbsUp, FaHeart, FaThumbsDown } from 'react-icons/fa'; // Icons for reactions
import { Link } from 'react-router-dom'; // Import Link for navigation

class job_detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobDetail: null,
      loading: true,
      error: null,
      likeCount: 0,
      loveCount: 0,
      dislikeCount: 0,
    };
  }

  componentDidMount() {
    const { id } = this.props.location.state || {};
    if (id) {
      this.fetchJobDetail(id);
    } else {
      this.setState({ error: 'No ID provided to fetch job details.', loading: false });
    }
  }

  fetchJobDetail = (id) => {
    axios
      .get(apiUrl + `serverside/latest_job.php?id=${id}`) // Assuming your API supports querying by ID
      .then((response) => {
        const matchedJob = response.data.find((job) => job.id === id); // Match the job by ID
        if (matchedJob) {
          this.setState({ jobDetail: matchedJob, loading: false });
        } else {
          this.setState({ error: 'Job not found.', loading: false });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ error: 'Failed to fetch job details.', loading: false });
      });
  };

  handleLike = () => {
    this.setState((prevState) => ({ likeCount: prevState.likeCount + 1 }));
  };

  handleLove = () => {
    this.setState((prevState) => ({ loveCount: prevState.loveCount + 1 }));
  };

  handleDislike = () => {
    this.setState((prevState) => ({ dislikeCount: prevState.dislikeCount + 1 }));
  };

  render() {
    const { jobDetail, loading, error, likeCount, loveCount, dislikeCount } = this.state;

    if (loading) {
      return <div className="text-center mt-5">Loading...</div>;
    }

    if (error) {
      return <div className="text-center mt-5 text-danger">{error}</div>;
    }

    return (
      <div style={{ marginTop: '40px', backgroundColor: '#f9f9f9', fontFamily: 'Lora, serif' }}>
        <Header />

        <head>
          <meta name="description" content={jobDetail.des} />
          <meta name="keywords" content="blog, article, react, share" />
          <meta property="og:title" content={jobDetail.title} />
          <meta property="og:description" content={jobDetail.des} />
          <meta property="og:url" content={jobDetail.url} />
          <title>{jobDetail.title}</title>
        </head>

        <div className="container mt-5">
          {/* Job Content */}
          {jobDetail && (
            <div className="row">
              {/* Left Panel for Job Content */}
              <div className="col-md-9">
                <div className="border p-4 mb-4" style={{ backgroundColor: '#ffffff', borderRadius: '10px' }}>
                  
                  <h4 className="font-weight-bold" style={{ fontSize: '28px', color: '#000' }}>{jobDetail.title}</h4>
                  <div className="job-content" style={{ fontSize: '16px', color: '#555' }}>
                    <p>{ReactHtmlParser(jobDetail.des)}</p>
                  </div>
                  <p><strong>Exam Category:</strong> {jobDetail.exam_category}</p>
                  <p><strong>Category:</strong> {jobDetail.category}</p>

                  {/* Reactions Section */}
                  <div style={{ marginTop: '20px' }}>
                    <h5 style={{ fontSize: '18px', fontWeight: 'bold' }}>Reactions:</h5>
                    <div className="d-flex justify-content-between" style={{ fontSize: '20px' }}>
                      <button onClick={this.handleLike} className="btn btn-light">
                        <FaThumbsUp style={{ color: '#007bff' }} /> 
                      </button>
                      <button onClick={this.handleLove} className="btn btn-light">
                        <FaHeart style={{ color: '#e83e8c' }} /> 
                      </button>
                      <button onClick={this.handleDislike} className="btn btn-light">
                        <FaThumbsDown style={{ color: '#dc3545' }} /> 
                      </button>
                    </div>
                  </div>

                  {/* Social Sharing */}
                  <div className="social-sharing" style={{ marginTop: '30px' }}>
                    <h5 style={{ fontSize: '18px', fontWeight: 'bold' }}>Share this article:</h5>
                    <ul className="social-icons list-unstyled d-flex" style={{ justifyContent: 'space-between' }}>
                      <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${jobDetail.url}`} target="_blank" rel="noopener noreferrer"><FaFacebook size={30} style={{ color: '#3b5998' }} /></a></li>
                      <li><a href={`https://twitter.com/intent/tweet?url=${jobDetail.url}`} target="_blank" rel="noopener noreferrer"><FaTwitter size={30} style={{ color: '#00acee' }} /></a></li>
                      <li><a href={`https://www.linkedin.com/shareArticle?url=${jobDetail.url}`} target="_blank" rel="noopener noreferrer"><FaLinkedin size={30} style={{ color: '#0e76a8' }} /></a></li>
                      <li><a href={`https://wa.me/?text=${jobDetail.url}`} target="_blank" rel="noopener noreferrer"><FaWhatsapp size={30} style={{ color: '#25d366' }} /></a></li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Right Panel for Advertisement */}
              <div className="col-md-3">
                <div className="border p-4 mb-4" style={{ backgroundColor: '#ffffff', borderRadius: '10px' }}>
                  <h4 style={{ fontSize: '20px', fontWeight: 'bold' }}>ErwaToday   </h4>
                  <p></p>
                </div>
              </div>
            </div>
          )}
        </div>

        <Footer />
      </div>
    );
  }
}

export default job_detail;
