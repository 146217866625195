import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; 
import Footer from './footer';
import Header from './header';
import apiUrl from './base_url';
import '../components/cssfile/question_paper.css';
import '../components/cssfile/job.css';
import ReactHtmlParser from 'react-html-parser';
import { FaArrowRight } from "react-icons/fa";

class Blog extends React.Component {
  constructor() {
    super();
    this.state = {
      other: [],
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    axios
      .get(apiUrl + 'serverside/latest_job.php')
      .then((response) => {
        this.setState({
          other: response.data.filter(item => item.category === 'other' && item.exam_category === 'other'),
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ error: 'There was an error fetching the data!', loading: false });
      });
  }

  renderSection = (title, items) => (
    <div className="col-lg-12 mb-4"> {/* Full width for the "other" section */}
      <div className="card shadow-lg border-0 rounded-4" style={{ border: 'none' }}>
        <div className="card-header bg-primary text-white text-center" style={{ fontSize: '18px', fontWeight: 'bold' }}>
          <h4 className="m-0">{title}</h4>
        </div>
        <div className="card-body p-4" style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {items.length > 0 ? (
            <ul className="list-unstyled">
              {items.slice(0, 5).map((item, index) => {
                const slug = item.topic_name.toLowerCase().replace(/[^a-z0-9]+/g, '-'); 
                return (
                  <li key={index} className={`mb-3 ${index < 5 ? 'highlight-latest' : ''}`}>
                    <Link 
                      to={{ pathname: `/job_detail/${slug}`, state: { url: item.url, id: item.id } }} 
                      className="text-decoration-none text-dark hover-highlight"
                      style={{ fontSize: '16px', fontWeight: '500' }}
                    >
                      <FaArrowRight /> <strong>{item.topic_name.slice(0, 70) + '...'}</strong>
                    </Link>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className="text-center text-muted" style={{ fontSize: '14px' }}>No {title.toLowerCase()} available.</p>
          )}
        </div>
      </div>
    </div>
  );

  render() {
    const { other, loading, error } = this.state;

    if (loading) {
      return <div className="text-center mt-5">Loading...</div>;
    }

    if (error) {
      return <div className="text-center mt-5 text-danger">{error}</div>;
    }

    return (
      <div className="" style={{ backgroundColor: '#f7f7f7' }}>
        <Header />
        <div className="container mt-5">
          <div className="row gy-12">
            {this.renderSection('Latest Published Article', other)}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Blog;
