import React from 'react';
import axios from 'axios';
import {
    PieChart, Pie, Cell, Tooltip, Legend
} from 'recharts';
import apiUrl from './base_url';
import '../components/cssfile/result.css';
import HOC from './HOC';

import { FaAngular, FaSignOutAlt, FaAward, FaTrophy, FaFileAlt, FaUsers, FaUserCircle, } from "react-icons/fa";
import { FcStatistics } from "react-icons/fc";

class Result extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            analysis: [],
            rank: [],
        };
    }

    componentDidMount() {
        const regid1 = this.props.location.state?.regid || this.props.location.state?.data?.regid;
        const exam_id1 = this.props.location.state?.id || this.props.location.state?.data?.exam_id;

        if (regid1 && exam_id1) {
            axios
                .get(`${apiUrl}serverside/analysis.php?exam_id=${exam_id1}&regid=${regid1}`)
                .then((response) => this.setState({ analysis: response.data }))
                .catch((error) => console.error("Analysis API Error:", error));

            axios
                .get(`${apiUrl}serverside/rank.php?exam_id=${exam_id1}&regid=${regid1}`)
                .then((response) => this.setState({ rank: response.data }))
                .catch((error) => console.error("Rank API Error:", error));
        } else {
            console.error("Missing regid or exam_id.");
        }
    }

    renderPieChart(title, value, total) {
        const data = [
            { name: 'Achieved By You', value },
            { name: 'Remaining out of Total', value: total - value },
        ];

        const COLORS = ['#0088FE', '#FFBB28'];

        return (
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <h5>{title}</h5>
                <PieChart width={200} height={200}>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={40}
                        outerRadius={60}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </div>
        );
    }

    render() {
        const { analysis, rank } = this.state;

        // Extracting values for Pie Charts
        const totalStudents = rank.length > 0 ? rank[0].total_students : 1;
        const rankObtained = rank.length > 0 ? rank[0].rank : 0;

        const totalScore = analysis.length > 0 ? analysis[0].total_score : 1;
        const scoreObtained = analysis.length > 0 ? analysis[0].total_score_obtained : 0;

        const totalQuestions = analysis.length > 0 ? analysis[0].total_q : 1;
        const attemptedQuestions = analysis.length > 0 ? analysis[0].total_qid : 0;

        const percentile = rank.length > 0 ? rank[0].percentile : 0;

        const accuracy = analysis.length > 0 ? analysis[0].accuracy : 0;

        const correctAnswers = analysis.length > 0 ? analysis[0].total_correct : 0;
        const incorrectAnswers = analysis.length > 0 ? analysis[0].total_incorrect : 0;

        const totalTime = analysis.length > 0 ? Math.floor(analysis[0].time / 60000) : 1;
        const timeTaken = analysis.length > 0 ? Math.floor((analysis[0].time - analysis[0].time_taken) / 60000) : 0;
        const time_taken1 = this.state.analysis.length > 0 ? this.state.analysis[0].time - this.state.analysis[0].time_taken : 0;

        return (
            <div className="container-fluid" style={{ marginTop: '40px', marginBottom: '50px', textAlign: 'center' }}>
                <h3>Overall Performance Summary</h3>

                <div className='row'>
                    <div className='col-sm-12'>
                                       
                                            <div className='row' id="myseries_card">
                                                <div className='col-sm-3' id="rank">
                                                    <FaAward id="rank1" />
                                                    {Array.isArray(this.state.rank) && this.state.rank.map((item) => (
                                                        <div>{item.rank}/{item.total_students}</div>
                                                    ))}
                    
                                                    <p id="titlep">Rank</p>
                                                </div> 
                                                <div className='col-sm-3' id="rank">
                                                    <FaTrophy id="rank2" />
                                                    {Array.isArray(this.state.analysis) && this.state.analysis.map((item) => (
                                                        <div>{item.total_score_obtained}/{item.total_score}</div>
                                                    ))}
                                                    <p id="titlep">Score</p>
                                                </div>
                                                <div className='col-sm-3' id="rank">
                                                    <FaFileAlt id="rank3" />
                                                    {Array.isArray(this.state.analysis) && this.state.analysis.map((item) => (
                                                        <div>{item.total_qid}/{item.total_q}</div>
                                                    ))}
                                                    <p id="titlep">Attempted</p>
                                                </div>
                                                <div className='col-sm-3' id="rank">
                                                    <FaUsers id="rank4" />
                                                    {Array.isArray(this.state.rank) && this.state.rank.map((item) => (
                                                        <div>{item.percentile.toFixed(2)}%</div>
                                                    ))}
                                                    <p id="titlep">Percentile</p>
                                                </div>
                                                <div className='col-sm-3' id="rank">
                                                    <FcStatistics id="rank4" />
                                                    {Array.isArray(this.state.analysis) && this.state.analysis.map((item) => (
                                                        <div>{item.accuracy.toFixed(2)}%</div>
                                                    ))}
                    
                                                    <p id="titlep">Accuracy</p>
                                                </div>
                    
                                            </div>
                    
                                            <div className='row' id="myseries_card">
                                                <div className='col-sm-3' id="rank">
                    
                                                    {Array.isArray(this.state.analysis) && this.state.analysis.map((item) => (
                                                        <div>{item.total_correct}/{item.total_q}</div>
                                                    ))}
                                                    <p id="titlep">Correct</p>
                                                </div>
                                                <div className='col-sm-3' id="rank">
                    
                                                    {Array.isArray(this.state.analysis) && this.state.analysis.map((item) => (
                                                        <div>{item.total_incorrect}/{item.total_q}</div>
                                                    ))}
                                                    <p id="titlep">Incorrect</p>
                                                </div>
                                                <div className='col-sm-3' id="rank">
                    
                                                    {Array.isArray(this.state.analysis) && this.state.analysis.map((item) => (
                                                        <div > ({Math.floor(time_taken1 / 60000)} /{Math.floor(item.time / 60000)})mins </div>
                                                    ))}
                                                    <p id="titlep">Time Taken </p>
                                                </div>
                    
                                                <div className='col-sm-2' id="rank">
                    
                                                </div>
                    
                                            </div>
                                        </div>
                </div>


                <div className="row">
                    <div className="col-sm-12" >
                        <div className="row" id="myseries_card">
                            {this.renderPieChart('Rank', rankObtained, totalStudents)}
                            {this.renderPieChart('Score', scoreObtained, totalScore)}
                            {this.renderPieChart('Attempted Questions', attemptedQuestions, totalQuestions)}
                            {this.renderPieChart('Percentile', percentile, 100)}
                            {this.renderPieChart('Accuracy', accuracy, 100)}
                            {this.renderPieChart('Correct Answers', correctAnswers, totalQuestions)}
                            {this.renderPieChart('Incorrect Answers', incorrectAnswers, totalQuestions)}
                            {this.renderPieChart('Time Taken (minutes)', timeTaken, totalTime)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HOC(Result);
