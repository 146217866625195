import React from 'react';
import axios from 'axios';
import HOC from './HOC';
import { Link } from 'react-router-dom';
import { FaAngular, FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import { Navbar, Nav, Card, Title, Text } from 'react-bootstrap';
import apiUrl from './base_url';

export default class Practice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: false
    };
  }
  
  handleFullScreenToggle = () => {
    const element = document.documentElement;
    if (document.fullscreenElement) {
      document.exitFullscreen();
      this.setState({ isFullScreen: false });
    } else if (element.requestFullscreen) {
      element.requestFullscreen().then(() => {
        this.setState({ isFullScreen: true });
      }).catch((err) => {
        console.error(err);
      });
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen().then(() => {
        this.setState({ isFullScreen: true });
      }).catch((err) => {
        console.error(err);
      });
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen().then(() => {
        this.setState({ isFullScreen: true });
      }).catch((err) => {
        console.error(err);
      });
    }
  };

  componentDidMount() {
    this.handleFullScreenToggle();
  }

  render() {
    return (
      <div>
        <button onClick={this.handleFullScreenToggle}>
          {this.state.isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
        </button>
      </div>
    );
  }
}
