import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Card, Title, Text } from 'react-bootstrap';
import Footer from './footer';
import Header from './header';
import apiUrl from './base_url';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';

class Allseries extends React.Component {
  constructor() {
    super();
    this.state = {
      addcategory: [],
      category: '',
    };
  }

  componentDidMount() {
    axios.get(apiUrl + 'serverside/parentcategory.php')
      .then(response => {
        console.log(response);
        this.setState({ addcategory: response.data });
      })
      .catch(error => {
        console.log(error);
      });
  }

  addSeries = (event, category) => {
    event.preventDefault();
    const regid = localStorage.getItem('regid');

    if (!regid) {
      window.location.href = '/login';
      return; // stop further execution of the function
    }

    this.setState({ category }); // update the category state variable
    axios.post(apiUrl + 'serverside/addseries.php', { category, regid })
      .then(response => {
        window.location.href = '/dashboard';
      })
      .catch(error => {
        console.log(error);
        alert(error);
      });
  };

  // Function to handle "View More" toggle
  toggleDescription = (index) => {
    const updatedCategories = [...this.state.addcategory];
    updatedCategories[index].isExpanded = !updatedCategories[index].isExpanded; // Toggle visibility
    this.setState({ addcategory: updatedCategories });
  };

  render() {
    return (
      <div style={{ backgroundColor: 'white' }}>
        <Header />
        <div className='container'>
          <div className="row mt-5">
            {this.state.addcategory
              .filter((item) => item.status == 1) // Include only active test series
              .map((item, index) => (
                <div key={index} className="col-lg-3 col-md-4 col-sm-6 mb-4">
                  <div className="card shadow-sm" style={{ borderRadius: '10px' }}>
                    {/* Image Wrapper for Centering */}
                    <div className="d-flex justify-content-center align-items-center mt-3">
                      <img
                        src={item.image}
                        className="card-img-top"
                        alt={item.topic_name}
                        style={{
                          height: '150px',
                          width: '150px',
                          borderRadius: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title text-center">{item.topic_name}</h5>

                      {/* Display truncated or full description based on state */}
                      <div className="topic-description" style={{ marginBottom: "20px", color: "#666", lineHeight: "1.6" }}>
                        {ReactHtmlParser(item.isExpanded ? item.total_set : item.total_set.slice(0, 300) )}
                      </div>

                      {/* Conditionally render the "View More" button */}
                      {item.total_set.length > 300 && (
                        <button
                          className="btn btn-link p-0 text-primary"
                          onClick={() => this.toggleDescription(index)}>
                          {item.isExpanded ? 'Show Less' : 'View More'}
                        </button>
                      )}

                      <button
                        className="btn btn-outline-primary w-100 mt-3 rounded-pill"
                        onClick={(e) => this.addSeries(e, item.category)}
                      >
                        Get Started Now
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Allseries;
