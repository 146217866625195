import React from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';


class Footer extends React.Component {
  render() {
    return (
      <div id="footer" className="footer-bg">
        <Navbar expand="lg">
          <Container>
            <Row>
              <Col lg={4} className="mb-3 mb-lg-0">
                <Navbar.Brand className="footer-brand">
                  <h5>ErwaToday.com</h5>
                </Navbar.Brand>
                <p>B.L SAHA ROAD TOLLYGUNJ, KOLKATA WEST BENGAL -700053</p>
                <p>9608372362</p>
                <p>administrative@erwatoday.com</p>
              </Col>
              <Col lg={4} className="mb-3 mb-lg-0">
                <h5>Connect with Us</h5>
                <a href="https://www.facebook.com/skhackergh/" className="footer-link"><FaFacebook /> Facebook</a><br />
                <a href="https://twitter.com/sikanda47058982" className="footer-link"><FaTwitter /> Twitter</a><br />
                <a href="https://www.instagram.com/_____sikandar/" className="footer-link"><FaInstagram /> Instagram</a>
              </Col>
              <Col lg={4} className="mb-3 mb-lg-0">
                <h5>Company</h5>
                <a href="/aboutus" className="footer-link">About Us</a><br />
                <a href="/contactus" className="footer-link">Contact Us</a><br />
                <a href="/privacy" className="footer-link">Privacy Policy</a><br />
                <a href="/termscondition" className="footer-link">Terms and Conditions</a><br />
                <a href="/paymentc" className="footer-link">Payment terms / Refund and Cancellation Policy</a>
              </Col>
            </Row>
            <hr className="footer-divider" />
            <p className="text-center footer-text">&copy; 2022-2025 ErwaToday.com. All Rights Reserved.</p>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default Footer;
