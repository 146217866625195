import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FaAngular, FaHome } from "react-icons/fa";
import logo from './img/erwatodaylogo.png';
import './cssfile/header.css';

class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Navbar 
          collapseOnSelect 
          expand="lg" 
          className="animated-navbar" 
          bg="white" 
          
        >
          <Navbar.Brand className="navbar-brand" href="/home">
            <img src={logo} alt="Logo" className="navbar-logo" />
            ErwaToday
          </Navbar.Brand>
          <Navbar.Toggle 
            aria-controls="responsive-navbar-nav" 
            className="custom-toggler" 
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto animated-nav-links">
              <Nav.Link as={Link} to="/">
                <FaHome /> Home
              </Nav.Link>


              <NavDropdown title="Test Series" id="nav-dropdown" className="animated-dropdown">
                <NavDropdown.Item as={Link} to="/allseries">
                  Series
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/question_paper_download">
                  Question Paper Download
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/question_paper">
                 Question Paper PDF
              </Nav.Link>

              <Nav.Link as={Link} to="/Job">
                Latest Job
              </Nav.Link>
              <Nav.Link as={Link} to="/intrance">
                Entrance Exam
              </Nav.Link>

              <Nav.Link as={Link} to="/blog">
                Blog
              </Nav.Link>
              


            </Nav>
            <Nav className="ml-auto">
  <Nav.Link 
    as={Link} 
    to="/login" 
    className="btn btn-light animated-signup" 
   
  >
    Signin/Signup
  </Nav.Link>


            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Header;
