import React from 'react';
import axios from 'axios';
import apiUrl from './base_url';

export default class Questiond extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question:[],
      sec_id:'',
    };
  }

  componentDidMount() {
    const sec_id = this.props.sec_id;
    const param = sec_id;
    axios.get(apiUrl+`serverside/getquestion.php?sec_id=${param}`)
        .then(response => {
            console.log(response.data);
            this.setState({ question: response.data });
        })
        .catch(error => {
            console.log(error)
        })
  }
  
  render() {
    return (
      <div className='row'>
        {Array.isArray(this.state.question) && this.state.question.map((item)=>(
          <div key={item.q_id}>{item.question}</div>  
        ))}
      </div>
    );
  }
}
