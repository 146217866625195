import React from "react";
import Mockheader from "./mock_header";
import Header from "./header";
import Footer from "./footer";

export default function Hoc(Component){
    function NewComponent(props){
        return(
            <>
<Header />
            <div className="row" id="header_row">
           
            <div className="col-sm-3">
            <Mockheader />
            </div>
            <div className="col-sm-8">
            <Component {...props}/>
            </div>
            <div className="col-sm-1">

            </div>

          </div>
         
            </>
           
        );
    }
    return NewComponent;
}