import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/header.js';
import './components/cssfile/header.css';

import Footer from './components/footer';
import { Route, Link, BrowserRouter as Router, BrowserRouter ,Switch,Redirect} from 'react-router-dom' 
import Home from './components/home';  
import Error from './components/Error';
import Login from './components/login';
import Signup from './components/signup';
import User from './components/user';
import Blog from './components/blog';
import Ca from './components/ca';
import Dashboard from './components/dashboard';
import Myseries from './components/myseries';
import payment from './components/payment';
import  Instruction from './components/instruction';
import Edashboard from './components/edashboard';
import Practice from './components/practice';
import Tempexam from './components/tempexam';
import Section from './components/getsection';
import Questiond from './components/getquestiond';
import Result from './components/result';
import Solution from './components/solution';
import AboutUs from './components/aboutus';
import ContactUs from './components/contactus';
import PrivacyPolicy from './components/privacy';
import TermsAndConditions from './components/termscondition';
import Allseries from './components/allseries';
import PaymentC from './components/paymentc';
import Job from './components/job.js';
import Question_paper from './components/question_paper.js';
import Intrance from './components/intrance.js';
import job_detail from './components/job_detail.js';
import Question_paper_download from './components/question_paper_download.js';




function App() {
  return (
    <div className="App" id="header" style={{ backgroundColor: '#f2f2f2'}}>
    <BrowserRouter>
   
    <Router>
     <Switch>
    <Route exact path="/" component={Home} />  
      <Route exact path="/login" component={Login} />  
      <Route path="/signup" component={Signup} /> 
      <Route path="/user" component={User} />
      <Route path="/home" component={Home} />
      <Route path="/blog" component={Blog} />
      <Route path="/ca" component={Ca} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/payment" component={payment} />
      <Route path="/instruction" component={Instruction} />
     <Route path="/edashboard" component={Edashboard} />
     <Route path="/allseries" component={Allseries} />

     <Route path="/tempexam" component={Tempexam} />
     <Route path="/getquestiond" component={Questiond} />
     <Route path="/getsection" component={Section} />
     <Route path="/result" component={Result} />
     <Route path="/solution" component={Solution} />
     <Route path="/job" component={Job}/>
     <Route path="/question_paper" component={Question_paper}/>
     <Route path="/intrance" component={Intrance} />

     <Route path="/aboutus" component={AboutUs} />
     <Route path="/contactus" component={ContactUs} />
     <Route path="/privacy" component={PrivacyPolicy} />
     <Route path="/termscondition" component={TermsAndConditions} />
     <Route path="/paymentc" component={PaymentC} />
     <Route path="/job_detail/:slug" component={job_detail} />
     <Route path="/question_paper_download" component={Question_paper_download }/>
     

     
      

       <Route path="/practice" component={Practice} />
      <Route path="/:url" component={Myseries} />
      
      
    
      {<Route   component={Error} />}
      </Switch>
    </Router>
    </BrowserRouter>  
    </div>

  );
}


export default App;
