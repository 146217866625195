import React from 'react';
import axios from 'axios';
import HOC from './HOC';
import moment from 'moment';
import apiUrl from './base_url';


import '../components/cssfile/dashboard.css';
import'../components/cssfile/solution.css';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Link } from 'react-router-dom';
import { FaAngular, FaSignOutAlt, FaUserCircle, FaBars, FaCheck } from "react-icons/fa";
import { Container, Row, Col, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

class Solution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

              

            values: [true,], fullscreen: true, show: false, isFullScreen: false, showSidePanel: false,
            timeLeft: null,
            currentQuestionNumber: 0,

            exam_id: '',
            instruction: [],
            dcategory: [],

            time_back: [],
            markedbackup: [],
            savedbackup: [],
            visitedbackup: [],

            totalSavedQuestions: 0,

            section: [],
            question: [],

            currentQuestionIndex: null,
            answer: '',
            currentSection: null,

            showAnswer: false,
            

        };
        this.toggleSidebar = this.toggleSidebar.bind(this);

    }
    ///////////////////This part for toggle system///////////////////////////////////
    handleFullScreenToggle = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
            this.setState({ isFullScreen: false });
        } else {
            document.documentElement.requestFullscreen();
            this.setState({ isFullScreen: true });
        }
    };

    toggleSidebar() {
        this.setState({ showSidebar: !this.state.showSidebar });
    }

    handleShow = (breakpoint) => {
        this.setState({
            fullscreen: breakpoint,
            show: true
        });
    }

    ///////////////This part for data loading/////////////////////////////////

    componentDidMount() {
        ///////////this is for section and question part ///////////
        const exam_id = this.props.location.state?.exam_id || localStorage.getItem('exam_id1');
        if (!exam_id) {
            // handle the error here
            console.error('exam_id is not defined');
            return;
        }
    
        localStorage.setItem('exam_id1', exam_id);
    
        this.loadQuestion(this.state.currentQuestionIndex, exam_id);
    
        const param = exam_id;
        axios.get(apiUrl+`serverside/getsection.php?exam_id=${param}`)
            .then(response => {
                this.setState({ section: response.data });
            })
            .catch(error => {
                console.log(error)
            })
    
        const param5 = exam_id;
        axios
            .get(apiUrl+`serverside/dashboard_category.php?exam_id=${param5}`)
            .then((response) => {
                this.setState({ dcategory: response.data });
            })
            .catch((error) => {
                console.log(error);
            });
        const regid = localStorage.getItem('regid');
        let totalTime, startTime;
    }
    
    
    loadQuestion = (index) => {
        const regid1 = localStorage.getItem('regid');
        const exam_id1 = localStorage.getItem('exam_id1');
        axios
            .get(apiUrl+`serverside/marked_backup.php?exam_id=${exam_id1}&regid=${regid1}`)
            .then((response) => {
                // console.log(response.data)
                this.setState({ markedbackup: response.data });
            })
            .catch((error) => {
                console.log(error);
            });


            axios.get(apiUrl + 'serverside/save_backup.php?exam_id=' + exam_id1 + '&regid=' + regid1)
            .then((response) => {
                if (Array.isArray(response.data)) {
                    this.setState({ savedbackup: response.data });
                } else {
                    console.error('Unexpected data format for savedbackup:', response.data);
                    this.setState({ savedbackup: [] }); // Fallback to empty array
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({ savedbackup: [] }); // Fallback to empty array in case of error
            });
        

        axios
            .get(apiUrl+`serverside/visited_backup.php?exam_id=${exam_id1}&regid=${regid1}`)
            .then((response) => {
                console.log(response.data)
                this.setState({ visitedbackup: response.data });
            })
            .catch((error) => {
                console.log(error);
            });



        const param = localStorage.getItem('secl_id');
        // fetch a single question from the server by index
        axios
            .get(apiUrl+`serverside/getquestion.php?sec_id=${param}&index=${index}`)
            .then((response) => {
                this.setState({
                    question: response.data,
                    currentQuestionIndex: index,
                });
            })
            .catch((error) => {
                console.log(error);
            });

    }


    loadAllQuestions = (sec_id, section_name) => {

        const secl_id = localStorage.setItem('secl_id', sec_id);
        axios.get(apiUrl+`serverside/getquestion.php?sec_id=${sec_id}`)
            .then(response => {
                // console.log(response.data);
                this.setState({ question: response.data, currentSection: section_name, });
                const currentSectionIndex = this.state.section.findIndex(section => section.sec_id === sec_id);
                const nextSection = this.state.section[currentSectionIndex + 1];
                if (nextSection && response.data.length === 0) {
                    // If there is a next section and the current section has no questions, load the questions for the next section
                    this.loadAllQuestions(nextSection.sec_id);
                } else {
                    // Otherwise, the current section has questions, so load the first question
                    this.loadQuestion(0);
                }
            })
            .catch(error => {
                console.log(error)
            })
    }




    handleNextClick() {

        const regid = localStorage.getItem('regid');
        const email = localStorage.getItem('email');
        const exam_id = localStorage.getItem('exam_id');
        const dcategory = this.state.dcategory;
        const a_id = dcategory[0].a_id;
        const { question, currentQuestionIndex } = this.state;
        const currentQuestion = question && question[currentQuestionIndex];
        const q_id = currentQuestion.q_id;

        const answerElement = document.querySelector('input[name="answer"]:checked');
        let answer = answerElement ? answerElement.value : 0;

        // Prepare the data to send to the server
        const data = {
            email: email, // Replace with the user's email address
            regid: regid, // Replace with the user's registration ID
            exam_id: exam_id, // Replace with the ID of the current exam
            a_id: a_id, // Replace with the ID of the current attempt
            q_id: q_id,
            answer: answer,
        };

        // Make an API call to the server to save the selected answer
        axios.post(apiUrl+'serverside/savequestion.php', data)
            .then(response => {
                console.log(response.data);
                if (answerElement) {
                    answerElement.checked = false;
                }
            })
            .catch(error => {
                console.log(error);
            });


        // Make an API call to the server to save the selected answer
        axios.post(apiUrl+'serverside/visitedquestion.php', data)
            .then(response => {
                console.log(response.data);
                if (answerElement) {
                    answerElement.checked = false;
                }
            })
            .catch(error => {
                console.log(error);
            });

        const nextIndex = this.state.currentQuestionIndex + 1;

        if (nextIndex < this.state.question.length) {
            // if there are more questions, load the next one
            this.loadQuestion(nextIndex)
        } else {
            // if we've reached the end of the questions, disable the Next button
            const { currentQuestionIndex, section, currentQuestionNumber } = this.state;
            const currentSectionIndex = section.findIndex(sec => sec.sec_id === currentQuestionNumber.sec_id);
            // alert(currentSectionIndex);

            if (currentQuestionNumber && currentQuestionNumber.question && currentQuestionIndex < currentQuestionNumber.question.length - 1) {
                // if there are more questions in the current section, load the next one
                this.loadQuestion(currentQuestionIndex - 1);
            } else if (currentSectionIndex < section.length - 1) {
                // if we've reached the end of the current section, but there are more sections,
                // reload all the questions in the next section and update the currentQuestionNumber state
                const nextSection = section[currentSectionIndex + 1];
                this.loadAllQuestions(nextSection.sec_id, nextSection.section_name);
                this.setState({ currentQuestionNumber: nextSection, currentQuestionIndex: 0 });
            } else {
                // if we've reached the end of all the questions and sections, reload all the questions from the beginning
                this.loadAllQuestions(section[0].sec_id);
                this.setState({ currentQuestionNumber: section[0], currentQuestionIndex: 0 });
            }
        }
    }



    

    render() {
        const { values, show, fullscreen } = this.state;
        const { isFullScreen } = this.state;
        const { timeLeft } = this.state;
        const { section } = this.state;

        const { question, currentQuestionIndex } = this.state;
        const currentQuestion = question && question[currentQuestionIndex];

        const email = localStorage.getItem('email');
        const regid = localStorage.getItem('regid');

        // alert(regid);
        if (!email || !regid) {
            this.props.history.push('/login'); // Redirect to login page
            return null; // Render nothing
        }

        const totalSavedQuestions = Array.isArray(this.state.savedbackup) ? this.state.savedbackup.length : 0;
        const totalMarkedQuestions = Array.isArray(this.state.markedbackup) ? this.state.markedbackup.length : 0;
        const totalVisitedQuestions = Array.isArray(this.state.visitedbackup) ? this.state.visitedbackup.length : 0;
        const totalUnsavedVisitedQuestions = totalVisitedQuestions - totalSavedQuestions + 1;
        const totalUnVisitedQuestions = totalVisitedQuestions - totalUnsavedVisitedQuestions - totalMarkedQuestions;


       
          const handleCopy = (e) => {
            e.preventDefault();
            alert('Copying text is not allowed on this page.');
          };
      

        return (



          <Container onCopy={handleCopy} fluid style={{ marginTop: '-60px', width: '100%', backgroundColor: 'white' }}>
          <div id="top_header" className="row" style={{ display: 'flex', justifyContent: 'space-between', height: '45px', boxShadow: "1px 0px 4px #9E9E9E" }}>
            {this.state.dcategory.map((item) => (
              <p><span style={{ color: '#118585' }}>ErwaToday</span> {item.topic_name}</p>
            ))}
           
          </div>
        
          <Row style={{ display: 'flex', justifyContent: 'space-between', margin: '0px' }}>
            <Col sm={9} className="d-block d-sm-none" style={{ marginTop: '5px' }}>
              {values.map((v, idx) => (
                <Button key={idx} className="me-2 mb-2" id="toggle_button" onClick={() => this.handleShow(v)}>
                  <FaBars />
                  {typeof v === 'string' && `below ${v.split('-')[0]}`}
                </Button>
              ))}
        
              <button onClick={this.handleFullScreenToggle} className="btn btn-outline-primary" style={{ height: '35px', paddingLeft: '8px' }}>
                {this.state.isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
              </button>{' '}
              <Link to={{ pathname: `/dashboard` }}>
                <button className="btn btn-outline-primary" style={{ height: '35px', padding: '3px' }}>Home</button>
              </Link>

              
            </Col>
        
            <Col sm={9} style={{ overflowY: 'scroll', height: '500px', backgroundColor: '#f8f9fa', borderRadius: '8px', padding: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              {/* Header Section */}
              <div id="top_header1" className="row" style={{ overflowX: 'auto', height: '35px', alignItems: 'center', marginBottom: '10px', padding: '5px 10px', backgroundColor: '#e9ecef', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                <span style={{ fontWeight: '600', marginRight: '10px' }}>Section:</span>
                {Array.isArray(section) && section.map((item) => (
                  <div key={item.sec_id} style={{ marginRight: '10px' }}>
                    <Link
                      className="btn btn-outline-secondary"
                      onClick={() => this.loadAllQuestions(item.sec_id, item.section_name)}
                      id="section_bar"
                      style={{ padding: '5px 10px', fontSize: '14px', borderRadius: '20px', transition: 'transform 0.3s ease' }}
                    >
                      {item.section_name}
                    </Link>
                  </div>
                ))}
              </div>
        
              {/* Language Selector */}
              <div style={{ float: 'right', marginBottom: '15px', fontSize: '14px' }}>
                <span style={{ fontWeight: '500', marginRight: '10px' }}>View In:</span>
                <select disabled style={{ padding: '5px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ced4da' }}>
                  <option value="">...select...</option>
                  <option value="hi">Hindi</option>
                  <option value="en">English</option>
                </select>
              </div>
        
              {/* Question Display */}
              <div className="row">
                <div>
                  {currentQuestion ? (
                    <div>
                      <p style={{ fontWeight: '700', fontSize: '16px', marginBottom: '10px' }}>
                        Question No.{currentQuestionIndex + 1} {' '}
                        <span id="year">{ReactHtmlParser(currentQuestion.year)}</span>
                      </p>
                      <p style={{ fontSize: '15px', lineHeight: '1.6' }}>
                        {ReactHtmlParser(currentQuestion.question)}
                      </p>
        
                      {/* Options */}
                      <div style={{ marginTop: '10px' }}>
                        {['option1', 'option2', 'option3', 'option4'].map((option, index) => (
                          <div key={index} style={{ marginBottom: '10px' }}>
                            <input
                              type="radio"
                              name="answer"
                              value={currentQuestion[option]}
                              id={`rad-${index}`}
                              onChange={this.handleChange}
                              style={{ marginRight: '8px' }}
                            />
                            <label
                              htmlFor={`rad-${index}`}
                              id="rad"
                              className={`
                                ${Array.isArray(this.state.savedbackup) &&
                                  this.state.savedbackup.some(
                                    (item) =>
                                      item.q_id === currentQuestion.q_id &&
                                      item.answer === currentQuestion[option]
                                  )
                                  ? 'matched-answer'
                                  : ''}
                                ${Array.isArray(this.state.markedbackup) &&
                                  this.state.markedbackup.some(
                                    (item) =>
                                      item.q_id === currentQuestion.q_id &&
                                      item.answer === currentQuestion[option]
                                  )
                                  ? 'marked-answer'
                                  : ''}
                              `}
                            >
                              {ReactHtmlParser(currentQuestion[option])}
                            </label>
                          </div>
                        ))}
                      </div>
        
                      {/* Conditional Answer Display */}
                      {this.state.showAnswer && (
                        <div style={{ marginTop: '15px' }}>
                          <p>
                            <strong>Correct Answer:</strong>{' '}
                            {ReactHtmlParser(currentQuestion.answer)}
                          </p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <h3
                      id="h3"
                      style={{
                        textAlign: 'center',
                        fontWeight: '600',
                        color: '#6c757d',
                        marginTop: '20px',
                      }}
                    >
                      Click on Any Section to View Question !!
                    </h3>
                  )}
        
                  {/* Action Buttons */}
                  {currentQuestion && (
                    <div style={{ position: 'fixed', bottom: '10px', left: '60%', transform: 'translateX(-50%)', display: 'flex', gap: '10px', zIndex: '1000' }}>
                      <button className="btn btn-primary" id="next_button" onClick={() => this.setState({ showAnswer: !this.state.showAnswer })}>
                        {this.state.showAnswer ? 'Hide Answer' : 'View Answer'}
                      </button>
                      <button className="btn btn-primary" id="next_button" onClick={() => this.handleNextClick()}>
                        Next Question
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Col>
        
            <Col col={3} className={this.state.showSidebar ? "d-block" : "d-none d-sm-block"} id="myseries_carde" style={{ marginLeft: '0px', marginRight: '0px', paddingRight: '0px', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', padding: '15px' }}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px', fontSize: '18px', fontWeight: 'bold' }}>
                  <FaUserCircle style={{ fontSize: '30px', marginRight: '10px' }} />
                  {email}
                </div>
                <hr />
                <div className="row" style={{ marginLeft: '10px', marginBottom: '15px', gap: '10px' }}>
                  <button id="button_plate" className="btn btn-success" style={{ flex: 1 }}>
                    {totalSavedQuestions} Answered
                  </button>
                  <button id="button_plate" className="btn btn-primary" style={{ flex: 1 }}>
                    {totalMarkedQuestions} Marked
                  </button>
                  <button id="button_plate" className="btn btn-light" style={{ flex: 1 }}>
                    Not Visited
                  </button>
                  <button id="button_plate" className="btn btn-danger" style={{ flex: 1 }}>
                    Not Answered
                  </button>
                </div>
                <hr />
                <h6 id="section" style={{ marginBottom: '15px', fontWeight: 'bold' }}>
                  Section: {this.state.currentSection}
                </h6>
                <div className="container" style={{ height: '270px', overflow: 'auto', overflowX: 'hidden', padding: '10px', backgroundColor: '#ffffff', border: '1px solid #dee2e6', borderRadius: '8px' }}>
                  <div className="row" style={{ gap: '10px', display: 'flex', flexWrap: 'wrap', padding: '5px' }}>
                    {Array.isArray(this.state.question) && this.state.question.map((item, index) => {
                      const isMarked = Array.isArray(this.state.markedbackup) && this.state.markedbackup.some((markedItem) => markedItem.q_id.toString() === item.q_id.toString());
                      const isSaved = Array.isArray(this.state.savedbackup) && this.state.savedbackup.some((savedItem) => savedItem.q_id.toString() === item.q_id.toString());
                      const isVisited = Array.isArray(this.state.visitedbackup) && this.state.visitedbackup.some((visitedItem) => visitedItem.q_id.toString() === item.q_id.toString());
                      return (
                        <button
                          id="button_plate"
                          key={item.q_id}
                          onClick={() => this.loadQuestion(index)}
                          style={{
                            backgroundColor: isSaved ? 'green' : isMarked ? 'blue' : isVisited ? 'red' : 'white',
                            color: isVisited || isMarked || isSaved ? '#ffffff' : '#000000',
                            border: '1px solid #dee2e6',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px',
                            height: '40px',
                            margin: '5px',
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          {index + 1}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
        
              {show && (
                <Modal
                  show={show}
                  fullscreen={fullscreen}
                  onHide={() => this.setState({ show: false })}
                  centered
                  dialogClassName="custom-modal"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="modal-title">ErwaToday</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="modal-body">
                    <div className="user-info d-flex align-items-center mb-4">
                      <FaUserCircle className="user-icon me-2" />
                      <span className="user-email">{email}</span>
                    </div>
                    <hr />
                    <div className="question-container">
                      <div className="row g-2">
                        {Array.isArray(this.state.question) && this.state.question.map((item, index) => {
                          const isMarked = Array.isArray(this.state.markedbackup) && this.state.markedbackup.some((markedItem) => markedItem.q_id.toString() === item.q_id.toString());
                          const isSaved = Array.isArray(this.state.savedbackup) && this.state.savedbackup.some((savedItem) => savedItem.q_id.toString() === item.q_id.toString());
                          const isVisited = Array.isArray(this.state.visitedbackup) && this.state.visitedbackup.some((visitedItem) => visitedItem.q_id.toString() === item.q_id.toString());
                          return (
                            <div className="col-auto" key={item.q_id}>
                              <button
                                className={`question-button ${
                                  isSaved ? "btn-saved" : isMarked ? "btn-marked" : isVisited ? "btn-visited" : "btn-default"
                                }`}
                                onClick={() => this.loadQuestion(index)}
                              >
                                {index + 1}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </Col>
          </Row>
        </Container>
        


        );
    }
}

export default Solution;