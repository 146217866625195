import React from "react";
import axios from "axios";
import apiUrl from './base_url';

class Tempexam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentQuestionIndex: 0,
      sectionId: 6, // assume sectionId is passed in as a prop
      question: [], // array to hold all the questions
    };
  }

  componentDidMount() {
    // load the first question when component mounts
    this.loadQuestion(this.state.currentQuestionIndex);
  }

  loadQuestion(index) {
    // fetch a single question from the server by index
    axios
      .get(apiUrl+`serverside/getquestion.php?sec_id=${this.state.sectionId}&index=${index}`)
      .then((response) => {
        this.setState({
          question: response.data,
          currentQuestionIndex: index,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadAllQuestions() {
    // fetch all questions from the server
    axios
      .get(apiUrl+`serverside/getquestion.php?sec_id=${this.state.sectionId}`)
      .then((response) => {
        console.log(response.data)
        this.setState({
          question: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleNextClick() {
    const nextIndex = this.state.currentQuestionIndex + 1;
    if (nextIndex < this.state.question.length) {
      // if there are more questions, load the next one
      this.loadQuestion(nextIndex);
    } else {
      // if we've reached the end of the questions, disable the Next button
      this.setState({ currentQuestionIndex: nextIndex });
    }
  }
  

  render() {
    const { question, currentQuestionIndex } = this.state;
    const currentQuestion = question && question[currentQuestionIndex];
  
    return (
      <div>
        {currentQuestion ? (
          <div>
            <p>Question No.{currentQuestion.q_id}</p>
            <p>{currentQuestion.question}</p>
            <button onClick={() => this.handleNextClick()}>Next</button>
          </div>
        ) : (
          <div>No more questions</div>
        )}
      </div>
    );
  }
} 
export default Tempexam;
