import React from 'react';
import Header from './header';
import Footer from './footer';
import { FaBars, FaHome, FaBook, FaHeart, FaCartArrowDown, FaSearch, FaSignOutAlt ,FaListUl,FaLaptop,FaUniversity } from "react-icons/fa";
import { Button, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './cssfile/mockheader.css'; // Updated styles

export default class Mockheader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSidebar: window.innerWidth >= 768, // Sidebar is visible by default on larger screens
      isLoggedIn: localStorage.getItem('email') && localStorage.getItem('password')
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  toggleSidebar() {
    this.setState((prevState) => ({ showSidebar: !prevState.showSidebar }));
  }

  handleResize() {
    // Automatically show or hide the sidebar based on screen width
    if (window.innerWidth < 768) {
      this.setState({ showSidebar: false });
    } else {
      this.setState({ showSidebar: true });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Adjust visibility on initial render
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleLogout = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('regid');
    window.location.reload();
  };

  render() {
    const { isLoggedIn, showSidebar } = this.state;

    return (
      <Container fluid className="mockheader-container">
        <div className="d-block d-sm-none"  >
            <Button onClick={this.toggleSidebar} id="toggle" >
              <FaListUl   />{' '}Menu
            </Button> 
          </div>
        <Row>
          
          <div className={`sidebar ${showSidebar ? "sidebar-show" : "sidebar-hide"}`}>
            <div className="sidebar-content">
              <ul>
                <li><Link className="sidebar-link" to="/home"><FaHome /> Home</Link></li>
                <li><Link className="sidebar-link" to="/allseries"><FaBook /> Add Series</Link></li>
                <li><Link className="sidebar-link" to="/job"><FaLaptop /> Latest Job</Link></li>
                <li><Link className="sidebar-link" to="/intrance"><FaUniversity /> Entrance Exam</Link></li>
                <li><Link className="sidebar-link" to="question_paper"><FaSearch /> Question Paper PDF</Link></li>
                <li>
                  {isLoggedIn 
                    ? <button className="btn btn-logout" onClick={this.handleLogout}><FaSignOutAlt /> Logout</button>
                    : <Link to="/login" className="btn btn-login">Sign In</Link>
                  }
                </li>
              </ul>
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}
