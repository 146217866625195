import React from 'react';
import {Table} from 'react-bootstrap';
import axios from 'axios';
import {} from 'react-router-dom';
import { Route, Link, BrowserRouter as Router, BrowserRouter ,Switch,Redirect} from 'react-router-dom';
import './style.css'
import apiUrl from './base_url';


class User extends React.Component{
    constructor(){
        super();
        this.state={
            product: []
        }
        
    }


    DeleteUser=(id)=>{
       
         axios({
                 method: 'DELETE',
               url: 'http://localhost:5000/product/' + id
               });
               alert('Item is deleted')
               window.location.reload();
               
    }

    

   componentDidMount(){
       axios.get('http://localhost:5000/product')
       .then(response=>{
           console.log(response.data)
           this.setState({product:response.data})
       })
       .catch (error=>{
           console.log(error)
       })
   }


    render(){

          return(
            <div className="col-sm-11" >
                <h1>Product List</h1>
                <hr/>
    <Table  size="sm"  className="table">
     <thead>
      <tr style={{backgroundColor:"black",color:"white"}}>
      <th>S.No.:</th>
      <th>Title</th>
      <th>Price</th>
      <th>Description</th>
      <th>Image</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
     {this.state.product.map((item) => (
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.title}</td>
                                    <td>{item.price}</td>
                                    <td>{item.description}</td>
                                    <td><img className="img1" src={item.image}></img></td>
                                    <td>
                                    <Link  className="btn btn-success" to={"product/"+item.id} >Edit</Link>{' '}
                                    <button className="btn btn-danger" onClick={()=> this.DeleteUser(item.id)}>Delete</button>{' '}
                                    <Link  className="btn btn-success" to={"view/"+item.id} >View</Link>
                                    </td>
                                </tr>
                            ))}
  </tbody>
</Table>

                </div>
        )
    }
}

export default User;